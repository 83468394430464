import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useIsEmbedFeatureEnabled } from '@confluence/embed-utils';
import { useIsFolderEnabled } from '@confluence/folder-utils/entry-points/useIsFolderEnabled';

export const useIsCreationDropdownEnabled = ({ canShowFolder }: { canShowFolder: boolean }) => {
	const isDatabaseEnabled = useBooleanFeatureFlag('confluence.frontend.databases.enabled');
	const isSmartLinkEmbedEnabled = useIsEmbedFeatureEnabled();
	const isSmartLinkEmbedOptOutEnabled = useBooleanFeatureFlag('confluence.frontend.embeds.opt-out');
	const isDatabaseOptOutEnabled = useBooleanFeatureFlag('confluence.frontend.databases.opt-out');

	const { isWhiteboardCreationDropdownEnabled } = useIsWhiteboardFeatureEnabled();

	const { isFolderEnabled } = useIsFolderEnabled();

	return (
		isWhiteboardCreationDropdownEnabled() ||
		(isDatabaseEnabled && !isDatabaseOptOutEnabled) ||
		(isSmartLinkEmbedEnabled && !isSmartLinkEmbedOptOutEnabled) ||
		(canShowFolder && isFolderEnabled)
	);
};
